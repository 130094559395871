const toFixed = (num, fixed) => {
  fixed = fixed || 0
  fixed = Math.pow(10, fixed)
  return Math.floor(num * fixed) / fixed
}

const nFormatter = (num: number, digits: number) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return `${toFixed(num / si[i].value, digits)}`.replace(rx, '$1') + si[i].symbol
}

export default nFormatter
