import { useState } from 'react'
import { Box, Flex, ToggleButton } from '@traefiklabs/faency'
import CodeBlock, { CodeBlockProps } from './CodeBlock'

export type CodeSnippetType = CodeBlockProps & { name?: string }
export type CodeSnippetProps = {
  data: CodeSnippetType[]
  snippetMinHeight?: number
}

const CodeSnippet: React.FC<CodeSnippetProps> = ({ data = [], snippetMinHeight, ...rest }) => {
  const buttonNames = data.map((entry) => entry.name)
  const [selected, setSelected] = useState(buttonNames.length ? buttonNames[0] : null)
  const codeSnippet = data.find((e) => e.name === selected)

  return (
    <Box data-testid="code-snippet" {...rest}>
      <Flex sx={{ display: 'inline-flex' }}>
        {buttonNames.map((buttonName) => (
          <ToggleButton
            key={buttonName}
            mr={1}
            px={1}
            sx={{ fontSize: 12 }}
            value={buttonName}
            isToggled={selected === buttonName}
            onToggle={() => setSelected(buttonName)}
          >
            {buttonName}
          </ToggleButton>
        ))}
      </Flex>

      {!!codeSnippet && (
        <CodeBlock
          p="4"
          lang={codeSnippet.lang}
          text={codeSnippet.text}
          snippetMinHeight={snippetMinHeight}
          sx={{
            width: 500,
            borderRadius: 10,
            overflowX: 'auto',
          }}
          copyable
        />
      )}
    </Box>
  )
}

export default CodeSnippet
