import { useState, useEffect } from 'react'

let cachedParentIFrame

const useIframeResizer = (isAuthenticated) => {
  const [parentIFrame, setParentIFrame] = useState(null)
  const [pendingMsg, setPendingMsg] = useState()
  const [iFrameResizerisReady, setIFrameResizerReady] = useState(false)

  useEffect(() => {
    ;(window as any).iFrameResizer = {
      onReady: () => {
        setIFrameResizerReady(true)
        setParentIFrame((window as any).parentIFrame)
        cachedParentIFrame = (window as any).parentIFrame
      },
    }
  }, [])

  useEffect(() => {
    if (iFrameResizerisReady && parentIFrame) {
      parentIFrame.sendMessage({ isAuthenticated })
    }
  }, [iFrameResizerisReady, parentIFrame, isAuthenticated])

  // Handle sending of pending message waiting iFrameResizer to be ready
  useEffect(() => {
    if (parentIFrame && pendingMsg) {
      sendMessage(pendingMsg)
    }
  }, [parentIFrame, pendingMsg])

  const sendMessage = (msg) => {
    // If parentIframe is cached we can use it without waiting iframe to be ready:
    // cached iframed is a way to access parentIframe when page have changed
    if ((iFrameResizerisReady && parentIFrame) || (cachedParentIFrame && !pendingMsg)) {
      ;(parentIFrame || cachedParentIFrame).sendMessage(msg)

      if (msg === pendingMsg) {
        setPendingMsg(null)
      }
    } else if (!pendingMsg) {
      // If message sent before iFrameResizer is ready then save it to send it later
      setPendingMsg(msg)
    }
  }

  return [sendMessage]
}

export default useIframeResizer
