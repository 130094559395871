import React, { useState, useEffect } from 'react'
import { Box, BoxProps, Button } from '@traefiklabs/faency'
import Highlight, { defaultProps, Language } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/nightOwl'
import useParentIframe from '../hooks/use-parent-iframe'
import Prism from 'prism-react-renderer/prism'
;((typeof global !== 'undefined' ? global : window) as any).Prism = Prism

require('prismjs/components/prism-toml')

type Lang = Language | 'toml'
export type CodeBlockProps = BoxProps & {
  lang: Lang
  text?: string
  children?: string
  copyable?: boolean
  snippetMinHeight?: number
}

const CodeBlock: React.FC<CodeBlockProps> = ({ lang, text, children, copyable = false, snippetMinHeight, ...rest }) => {
  const initialCopyState = 'Copy'
  const [copyState, setCopyState] = useState(initialCopyState)
  const [sendMessage] = useParentIframe(true)

  const code = text || children

  useEffect(() => {
    let timer
    if (copyState !== initialCopyState) {
      timer = setTimeout(() => setCopyState(initialCopyState), 2500)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [copyState])

  return (
    <Highlight {...defaultProps} code={code} theme={theme} language={lang as Language}>
      {({ className, style, tokens, getTokenProps }) => (
        <Box pt={1} sx={{ position: 'relative', ...(snippetMinHeight ? { minHeight: snippetMinHeight } : {}) }}>
          {copyable && (
            <Button
              mt={1}
              sx={{ position: 'absolute', top: 0, right: 0, color: '#0091ff' }}
              title={copyState}
              onClick={async (e: React.MouseEvent): Promise<void> => {
                e.stopPropagation()
                setCopyState('Copied!')

                try {
                  await navigator.clipboard.writeText(code)
                } catch {
                  sendMessage({ type: 'copy-to-clipboard', value: code })
                }
              }}
            >
              {copyState}
            </Button>
          )}
          <Box as="pre" m={0} className={className} style={{ ...style, overflowX: 'auto' }} {...rest}>
            {tokens.map((line, i) => (
              <div key={`line-${i}`}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </Box>
        </Box>
      )}
    </Highlight>
  )
}

export default CodeBlock
